import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Linkedin, Github } from "react-bootstrap-icons";
import styles from "./Footer.module.scss";

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <Container>
        <Row>
          <Col xs={0} lg={3}></Col>
          <Col
            xs={12}
            lg={3}
            className="text-center text-lg-start d-flex flex-column  gap-1 mb-4"
          >
            <h6 className="mb-1">About Us</h6>
            <p className={`${styles["payoff"]}`} >We are a company dedicated to providing innovative solutions</p>
          </Col>
          <Col
            xs={12}
            lg={3}
            className="text-center text-lg-start d-flex flex-column  gap-1 mb-4"
          >
            <h6 className="mb-1">Contact</h6>
            <p>info@example.com</p>
            <p>+39 456 789</p>
          </Col>
          <Col
            xs={12}
            lg={3}
            className="text-center text-lg-start d-flex flex-column  gap-1 mb-4"
          >
            <h6 className="mb-1">Follow Us</h6>
            <div className="d-flex gap-2 justify-content-center justify-content-lg-start">
              <Linkedin className={styles.icon} size={25} />
              <Github className={styles.icon} size={25} />
            </div>
          </Col>
        </Row>
        <Row>
          <Col className= {`text-center mt-4`}>
            <p className= {`${styles["allRightsReserved"]}`}>
              &copy; {new Date().getFullYear()} Smart Bag Pro. All rights
              reserved.
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
