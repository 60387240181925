// Import react and router DOM
import React, { useState, useEffect,useMemo } from "react";
import { Link } from "react-router-dom";

// Import components
import { SpinnerGeneric, SpinnerButton } from "../../utils/spinners";
import ProductionSheetTableRow from "./productionSheetTableRow/productionSheetTableRow";
import ExtractDateTimeFromString from "../../utils/extractDateTimeFromString";
import addCommasToNumber from "../../utils/addCommasToNumber";

// Import bootstrap components
import { Row, Button, Col } from "react-bootstrap";
import { ArrowClockwise, PlusCircle } from "react-bootstrap-icons";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Stack from 'react-bootstrap/Stack';

// Import Ag-Grid styles
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { AgGridReact } from "ag-grid-react";

// Import custom styles and context
import styles from "./productionSheetsTable.module.scss";
import { useActiveUser } from "../../store/activeUserContext";

const ProductionSheetsTable = () => {
  const { activeUser } = useActiveUser();
  const [isLoading, setIsLoading] = useState(false);

  const [reloadData, setReloadData] = useState(false);
  const [rowData, setRowData] = useState([]);

  //Modale delle colonne
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const columnDefs = [
    {
      headerName: "Notes",
      field: "notes",
      editable: false,
      cellRenderer: (params) => (
        <ProductionSheetTableRow
          id={params.data._id}
          reloadData={reloadData}
          setReloadData={setReloadData}
        />
      ),
      // resizable: false,
      width: 20,
    },
    {
      field: "createdAt",
      headerName: "Created",
      cellClass: `${styles["user-select-text"]}`,
      valueFormatter: (params) => ExtractDateTimeFromString(params.value),
      sort: 'desc',
    },
    {
      field: "updatedAt",
      headerName: "Last Updated",
      cellClass: `${styles["user-select-text"]}`,
      valueFormatter: (params) => ExtractDateTimeFromString(params.value),
    },
    { headerName: "ID", field: "_id", filter: true },
    {
      selected: true,
      headerName: "Quantity",
      field: "quantity",
      filter: "agNumberColumnFilter",
      type: 'rightAligned',
      valueFormatter: (params) => addCommasToNumber(params.value), 
    },
    {
      selected: true,
      headerName: "Gross Total Weight",
      field: "grossProductionWeight",
      filter: "agNumberColumnFilter",
      type: 'rightAligned',
      valueFormatter: (params) => addCommasToNumber(params.value), 
    },
  ];

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 200,
      floatingFilter: true,
    };
  }, []);

  const autoSizeStrategy = useMemo(() => {
    return {
      type: 'fitCellContents',
    };
  }, []);

  const [columnsToSelect, setColumnsToSelect] = useState([
    {
      selected: true,
      headerName: "Gross Total Weight",
      field: "grossProductionWeight",
      filter: "agNumberColumnFilter",
    },
    {
      selected: true,
      headerName: "Net Total Weight",
      field: "netProductionWeight",
      filter: "agNumberColumnFilter",
    },
    {
      selected: true,
      headerName: "Quantity",
      field: "quantity",
      filter: "agNumberColumnFilter",
    },
  ]);

  const userId = activeUser._id;
  const token = localStorage.getItem("token");

  // Aggiungi useEffect per chiamare getProductionSheets quando il componente si monta o reloadData cambia
  useEffect(() => {
    setIsLoading(true);
    const getProductionSheets = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/api/productionSheets/user/${userId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error(response.statusText);
        }

        const data = await response.json();
        setRowData(data);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    // Chiamata a getProductionSheets quando il componente si monta o reloadData cambia
    getProductionSheets();
  }, [userId, reloadData, token]);

  const handleReloadClick = () => {
    // Imposta il valore di reloadData per forzare la ricarica dei dati
    setReloadData((prev) => !prev);
  };

  const [userColumns, setUserColumns] = useState([]);

  const changeColumns = (field) => {
    setColumnsToSelect((prevColumns) => {
      // Crea una nuova copia dell'array columnsToSelect
      const updatedColumns = prevColumns.map((column) =>
        column.field === field
          ? { ...column, selected: !column.selected }
          : column
      );

      setUserColumns(updatedColumns);
      console.log(userColumns);

      return updatedColumns;
    });
  };

  const saveChanges = async () => {
    const selectedUserColumns = userColumns.filter((column) => column.selected);
    const cleanedUserColumns = selectedUserColumns.map(
      ({ selected, ...rest }) => rest
    );
    const mergedColumns = [...columnDefs, ...cleanedUserColumns];
    console.log(mergedColumns);
    handleClose();
  };

  return (
    <>
      {/* <Container className="mb-3"> */}
      <Row>
        <Col xs={12} lg={12}>
          <Stack direction="horizontal" className="pt-3 pb-2" gap={3}>

          <Button
            variant="warning"
            as={Link}
            to="/dashboard/productionSheetPage"
            disabled={isLoading}
          >
            {isLoading ? (
              <>
                <SpinnerButton size={20} /> <span className="me-2"> Loading...</span>
              </>
            ) : (
              <>
                <PlusCircle size={20} className="me-1" /> New...
              </>
            )}
          </Button>

          <Button
            variant="success"
            onClick={handleReloadClick}
            disabled={isLoading}
            className="ms-auto"
          >
            {isLoading ? (
              <>
                <SpinnerButton size={20} />{" "}
                <span className="me-2"> Loading...</span>
              </>
            ) : (
              <>
                <ArrowClockwise size={20} className="me-1" /> Reload Data
              </>
            )}
          </Button>
         
          </Stack>


          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Modal heading</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                {/* Costruisco una lista di formcheck con columnToSelect */}
                {columnsToSelect.map((column) => (
                  <Form.Check
                    key={column.field}
                    type="checkbox"
                    label={column.headerName}
                    checked={column.selected}
                    onChange={() => changeColumns(column.field)}
                    className="mb-3"
                  />
                ))}
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" onClick={saveChanges}>
                Save Changes
              </Button>
            </Modal.Footer>
          </Modal>

          <div
            className={"grid-wrapper ag-theme-quartz"}
            style={{ width: "100%", height: "500px" }}
          >
            {isLoading ? (
              <SpinnerGeneric />
            ) : (
              <AgGridReact
                columnDefs={columnDefs}
                rowData={rowData}
                enableFilter={true}
                autoSizeStrategy={autoSizeStrategy}

                defaultColDef={defaultColDef}


              />
            )}
          </div>
        </Col>
      </Row>
      {/* </Container> */}
    </>
  );
};

export default ProductionSheetsTable;
