// Import react and router DOM
import React, { useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import {  ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";


//Import components
import NavBar from "./components/navbar/NavBar.jsx";
import LoginPage from "./views/loginPage/loginPage.jsx";
import HomePage from "./views/homepage/homepage.jsx";
import Footer from "./components/footer/Footer.jsx";
import NotFoundPage from "./views/notFoundPage/notFoundPage.jsx";
import CreateAccountPage from "./views/createAccountPage/createAccountPage.jsx";
import ProfilePage from "./views/profilePage/profilePage.jsx";
import AdminPage from "./views/adminPage/adminPage.jsx";
import ProductionSheetPage from "./views/dashboardPage/productionSheetPage/productionSheetPage.jsx";
import ProductionSheetListPage from "./views/dashboardPage/productionSheetsListPage/productionSheetsListPage.jsx";
import PrivateRoute from "./components/privateRoute/privateRoute.jsx";
import SoftwarePage from "./views/softwarePage/softwarePage.jsx";
import AboutPage from "./views/aboutPage/aboutPage.jsx";

//Import states, contexts and CSS
import 'bootstrap/dist/css/bootstrap.min.css';

import "./App.css";
import { ActiveUserProvider } from "./store/activeUserContext.jsx";


function App() {
  const [activeUser, setActiveUser] = useState();


  return (
    <>

      <BrowserRouter>
        <ActiveUserProvider value={{ activeUser, setActiveUser }}>
          <NavBar />
          <ToastContainer />

          <Routes>

            <Route path="/" element={<HomePage />} />

            <Route path="/login" element={<LoginPage />} />s
            <Route path="/createaccount" element={<CreateAccountPage />} />
            <Route path="/software" element={<SoftwarePage />} />
            <Route path="/about" element={<AboutPage />} />

            {/* Utilizza PrivateRoute per le rotte private */}
            <Route path="/" element={<PrivateRoute />}>
              <Route
                path="dashboard/productionSheetList"
                element={<ProductionSheetListPage />}
              />
              <Route path="/admin" element={<AdminPage />} />
              <Route path="/profile" element={<ProfilePage />} />
              <Route
                path="dashboard/productionSheetPage"
                element={<ProductionSheetPage />}
              />
              <Route
                path="dashboard/productionSheetPage/:id"
                element={<ProductionSheetPage />}
              />
            </Route>

            <Route path="*" element={<NotFoundPage />} />
          </Routes>
          <Footer />

        </ActiveUserProvider>
      </BrowserRouter>

    </>
  );
}

export default App;
