
  const addCommasToNumber = (number) => {
    const numberString = String(number);
    const parts = numberString.split(".");
    const integerPart = parts[0];
    const decimalPart = parts.length > 1 ? `.${parts[1]}` : "";
  
    const integerWithCommas = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    
    return integerWithCommas + decimalPart;
  };
  
export default addCommasToNumber;