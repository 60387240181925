// Import react and router DOM
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";

//Import components
import LoggedDropdown from "../loggedDropdown/LoggedDropdown.jsx";

//Import states, contexts and CSS
import { Container } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Button from "react-bootstrap/Button";
import styles from "./NavBar.module.scss";
import { List, BagHeartFill } from "react-bootstrap-icons";
import { useActiveUser } from "../../store/activeUserContext.jsx";

const NavBar = () => {
  const { activeUser } = useActiveUser();

  // Change color when scrolled
  const [color, setColor] = useState(false);

  const changeColor = () => {
    if (window.scrollY >= 100) {
      setColor(true);
    } else {
      setColor(false);
    }
  };

  window.addEventListener("scroll", changeColor);

  const isXsScreen = window.innerWidth <= 576;

  const navbarClass =
    window.location.pathname === "/"
      ? `fixed-top ${
          isXsScreen
            ? styles["myNavScrolled"]
            : color
            ? styles["myNavScrolled"]
            : styles["myNavNotScrolled"]
        }`
      : `sticky-top ${styles["myNavScrolled"]}`;

  return (
    <>
      <Navbar
        variant="dark"
        expand="lg"
        className={`${styles["myNavStyle"]} ${navbarClass}`}
      >
        <Container>
          <Navbar.Brand
            className={`${styles["navText"]} d-flex align-items-center`}
          >
            <NavLink to="/" className={`${styles["navLink"]} d-flex align-items-center`}>
              <BagHeartFill size={35} className="me-2"/>

              <p className="m-0">Smart Bag Pro</p>
            </NavLink>
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="basic-navbar-nav" bg="warning">
            <Button variant="warning">
              <List size={20} />
            </Button>
          </Navbar.Toggle>
          {activeUser ? (
            <div className="d-lg-none ">
              <LoggedDropdown className="" />
            </div>
          ) : (
            <></>
          )}
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              {activeUser ? (
                <></>
              ) : (
                <div
                  className={`${styles.myNavDivButtonsCollapsed} d-lg-none `}
                >
                  <Button
                    className={`${styles["navText"]}fw-bold mt-3 mb-1 w-100`}
                    variant="outline-success"
                    as={Link}
                    to="/createaccount"
                  >
                    Create Account
                  </Button>{" "}
                  <Button
                    className={`${styles["navText"]} fw-bold mb-3 w-100`}
                    variant="outline-secondary"
                    as={Link}
                    to="/login"
                  >
                    Log in
                  </Button>{" "}
                </div>
              )}

              {activeUser ? (
                <>
                  <NavLink
                    to="/dashboard/productionSheetList"
                    className={`${styles["navLink"]}`}
                  >
                    Dashboard
                  </NavLink>
                  {/* <NavLink className={`${styles["navLink"]}`}> Contact</NavLink> */}
                  <NavLink to="/about" className={`${styles["navLink"]}`}>
                    {" "}
                    About us
                  </NavLink>
                </>
              ) : (
                <>
                  <NavLink to="/software" className={`${styles["navLink"]}`}>
                    {" "}
                    Software
                  </NavLink>
                  {/* <NavLink className={`${styles["navLink"]}`}> Contact</NavLink> */}
                  <NavLink to="/about" className={`${styles["navLink"]}`}>
                    {" "}
                    About us
                  </NavLink>
                </>
              )}
            </Nav>

            {activeUser ? (
              <div className="position-relative">
                <div className={`d-none d-lg-block`}>
                  <LoggedDropdown />
                </div>
              </div>
            ) : (
              <div className={`${styles.myNavDivButtons} d-none d-lg-block`}>
                {/* <Div className={`${styles.myNavDivButtons}`}> */}
                <Button
                  className={`${styles["navText"]} fw-bold ms-3`}
                  variant="outline-warning"
                  as={Link}
                  to="/login"
                >
                  Log in
                </Button>{" "}
                <Button
                  className={`${styles["navText"]} fw-bold ms-3`}
                  variant="outline-warning"
                  as={Link}
                  to="/createaccount"
                >
                  Create Account
                </Button>{" "}
              </div>
            )}
            {/* <Button className={`d-xl-none ${styles.myNavStyle}`} variant="light">Log in</Button>{" "} */}
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {/* {activeUser ? <DashboardNavbar />  : <></>} */}
    </>
  );
};

export default NavBar;
