// Import react and router DOM
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

//Import states, contexts and CSS
import { Modal, Button } from "react-bootstrap";

// Components
import { SpinnerButton } from "../../../../utils/spinners";

const CopyModal = ({
  show,
  handleClose,
  handleConfirm,
  message,
  isLoading,
}) => {
  const [showSecondQuestion, setShowSecondQuestion] = useState(false);
  const navigate = useNavigate();

  const handleConfirmCopy = () => {
    handleConfirm();

    setShowSecondQuestion(true);

  };

  const handleBackToTable = () => {
    navigate("/dashboard/productionSheetList");
    setShowSecondQuestion(false);
    handleClose(); // Chiudi il modale dopo la risposta alla seconda domanda
  };

  const proceedToNewSheet = () => {
    navigate(
      `/dashboard/productionSheetPage/${localStorage.getItem(
        "editProductionSheetId"
      )}`
    );
    handleClose(); 
  };

  return (
    <Modal centered show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Confirm Action</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {showSecondQuestion ? (
          <>
            
            <p>
              Do you want to go back to the table or proceed to the new sheet?
            </p>
            <Modal.Footer>

            <Button variant="success" onClick={proceedToNewSheet}>
              Proceed to New Sheet
            </Button>
            <Button variant="secondary" onClick={handleBackToTable}>
              Back to list
            </Button>
            </Modal.Footer>

          </>
        ) : (
          <>

            <p>{message}</p>
                <Modal.Footer>
            <Button
              variant="primary"
              onClick={handleConfirmCopy}
              disabled={isLoading}
            >
              {isLoading ? (
                <>
                  <SpinnerButton /> <span className="me-2">Copying...</span>
                </>
              ) : (
                "Confirm Copy"
              )}
            </Button>
            <Button
              variant="secondary"
              onClick={handleClose}
              disabled={isLoading}
            >
              Cancel
            </Button>
            </Modal.Footer>

          </>
        )}
      </Modal.Body>

      <Modal.Footer>

      </Modal.Footer>
    </Modal>
  );
};

export default CopyModal;
