// Import react and router DOM
import React from "react";

//Import components
import ProductionSheetsTable from "../../../components/productionSheetsTable/productionSheetsTable.jsx";
import {DashboardNavbar,DashboardNavbarMobile} from "../../../components/dashboardNav/dashboardNavbar.jsx";

//Import states, contexts and CSS
import {  Row, Col } from "react-bootstrap";
import Card from "react-bootstrap/Card";

// import styles from "./dashboardPage.module.scss";

const ProductionSheetListPage = () => {
  return (
    <>
      <Row className="my-4">
      <Col lg={1} className="d-none d-lg-block">
          <DashboardNavbar />
        </Col>
        <Col xs={12} lg={10}>
          <Card className="mb-2" border="dark">
            <Card.Header>
              <h1 className={" text-center"}>Production Sheet List</h1>
            </Card.Header>
          </Card>
          <ProductionSheetsTable />
        </Col>
        <Col  lg={0}></Col>
      </Row>
      <Row className="my-4">
        <Col xs={12} className="d-lg-none">
          <DashboardNavbarMobile />
        </Col>
      </Row>
    </>
  );
};
export default ProductionSheetListPage;
