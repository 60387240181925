// Import react and router DOM
import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useActiveUser } from "../../store/activeUserContext";

const PrivateRoute = () => {
  const { activeUser } = useActiveUser();

  return activeUser ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoute;
