// Import react and router DOM
import React from "react";

//Import states, contexts and CSS
import { Modal, Button } from "react-bootstrap";

// Components
import { SpinnerButton } from "../../../../utils/spinners";

const DeleteModal = ({
  show,
  handleClose,
  handleConfirm,
  message,
  isLoading,
}) => {
  return (
    <Modal centered show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Confirm Action</Modal.Title>
      </Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={handleConfirm}
          disabled={isLoading}
        >
          {isLoading ? (
            <>
              <SpinnerButton /> <span className="me-2"> Deleting...</span>
            </>
          ) : (
            "Confirm"
          )}
        </Button>
        <Button variant="primary" onClick={handleClose} disabled={isLoading}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteModal;
