// Import react and router DOM
import React, { useState } from "react";
import countryList from "react-select-country-list";
import { useNavigate } from "react-router-dom";

// Import components
import { SpinnerButton } from "../../utils/spinners";
import { emptyCreateAccountForm } from "../../data/emptyForms";

// Import states, contexts, and CSS

import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import FloatingLabel from "react-bootstrap/FloatingLabel";
// import styles from "./createAccount.module.scss";
import { notifySuccess,notifyError } from "../toastify/toastify";


import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";


const CreateAccountForm = () => {
  // Import country list
  const countryOptions = countryList().getData();
  const navigate = useNavigate();

  // Variables and states
  const [valueCheckPassword, setValueCheckPassword] = useState();
  const [countryOfResidence, setCountryOfResidence] = useState(null);
  const [validated, setValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  const [createAccountForm, setCreateAccountForm] = useState(
    emptyCreateAccountForm
  );
  const LoginForm = { email: emptyCreateAccountForm.email , password: emptyCreateAccountForm.password };
  const [loginForm, setLoginForm] = useState(LoginForm);
  // Functions

  const handleCountryChange = (selectedOption) => {
    setCountryOfResidence(selectedOption);
    console.log(selectedOption);
    setCreateAccountForm({
      ...createAccountForm,
      countryOfResidence: countryOfResidence,
    });
  };

  const handleChange = (e) => {
    setCreateAccountForm({
      ...createAccountForm,
      [e.target.name]: e.target.value,
    });
    setLoginForm({...LoginForm, [e.target.name]: e.target.value });

  };

  const validateForm = () => {
    // Verifica obbligatorietà dei campi
    if (
      !createAccountForm.firstName ||
      !createAccountForm.lastName ||
      !createAccountForm.dateOfBirth ||
      !countryOfResidence ||
      !createAccountForm.email ||
      !createAccountForm.password ||
      !valueCheckPassword
    ) {
      notifyError("Please fill in all required fields.");
      return false;
    }

    // Verifica corrispondenza della password
    if (valueCheckPassword !== createAccountForm.password) {
      notifyError("Incorrect password");
      return false;
    }

    // Verifica maggiore età
    const birthDate = new Date(createAccountForm.dateOfBirth);
    const currentDate = new Date();
    const age = currentDate.getFullYear() - birthDate.getFullYear();

    if (age < 18) {
      notifyError("You must be at least 18 years old to register.");
      return false;
    }

    return true;
  };

  const submitValidForm = async (e) => {
    console.log("newform: ",createAccountForm);
    setIsLoading(true);
    try {
      if (!validateForm()) {
        return;
      }
  
      // Send request to the server
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/users`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(createAccountForm),
      });
  
      const data = await response.json();

      if (response.ok) {
        // Reset form state after successful submission
        console.log(loginForm);
        setCreateAccountForm(emptyCreateAccountForm);
        setCountryOfResidence(null);
        setValueCheckPassword("");
  
        notifySuccess("User registered successfully");
        navigate("/login");

      } else {
        // Logic for error handling if necessary
        notifyError(`Error: ${data.message}`);
      }
    } catch (error) {
      // Error handling in the catch block
      notifyError("Error during registration");
    }finally {
      setIsLoading(false);
    }
  
  };
  
  

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    form.checkValidity() === false
      ? event.stopPropagation()
      : submitValidForm(event);
    setValidated(true);
    
  };

  return (

    <Card >
      <Card.Body>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <h2 className={"my-1 mb-5"}>Let's create an account for you</h2>

          <Form.Group controlId="firstName" className={"mb-2"}>
            <FloatingLabel label="First name">
              <Form.Control
                required
                type="text"
                placeholder="First name"
                name="firstName"
                value={createAccountForm.firstName}
                onChange={handleChange}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </FloatingLabel>
          </Form.Group>

          <Form.Group controlId="lastName" className={"mb-2"}>
            <FloatingLabel label="Last name">
              <Form.Control
                type="text"
                placeholder="Last name"
                name="lastName"
                value={createAccountForm.lastName}
                onChange={handleChange}
                required
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </FloatingLabel>
          </Form.Group>
          <Form.Group controlId="dateOfBirth" className={"mb-2"}>
            <FloatingLabel label="Date of birth">
              <Form.Control
                required
                type="date"
                placeholder="Date of birth"
                name="dateOfBirth"
                value={createAccountForm.dateOfBirth}
                onChange={handleChange}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </FloatingLabel>
          </Form.Group>

          <Form.Group controlId="countryOfResidence" className={"mb-2"}>
            <FloatingLabel label="Country of residence">
              <Form.Select
                required
                value={countryOfResidence || ""}
                onChange={(e) => handleCountryChange(e.target.value)}
              >
                <option value="">Select a country of residence</option>
                {countryOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </FloatingLabel>
          </Form.Group>

          <Form.Group controlId="formBasicEmail" className={"mb-2"}>
            <FloatingLabel label="Email address">
              <Form.Control
                required
                type="email"
                placeholder=""
                name="email"
                value={createAccountForm.email}
                onChange={handleChange}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>

              <Form.Control.Feedback type="invalid">
                Please provide a valid email.
              </Form.Control.Feedback>
            </FloatingLabel>
          </Form.Group>

          <Form.Group controlId="password" className={"mb-2"}>
            <FloatingLabel label="Password">
              <Form.Control
                required
                type="password"
                placeholder="Password"
                name="password"
                value={createAccountForm.password}
                onChange={handleChange}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>

              <Form.Control.Feedback type="invalid">
                Please provide a valid password.
              </Form.Control.Feedback>
            </FloatingLabel>
          </Form.Group>

          <Form.Group controlId="passwordCheck" className={"mb-2"}>
            <FloatingLabel label="Password confirmation">
              <Form.Control
                required
                type="password"
                placeholder="Password confirmation"
                name="passwordCheck"
                value={valueCheckPassword}
                onChange={(e) => setValueCheckPassword(e.target.value)}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>

              <Form.Control.Feedback type="invalid">
                Please check your password confirmation.
              </Form.Control.Feedback>
            </FloatingLabel>
          </Form.Group>
          <Row className={"mb-5"}>
            <Col xs={12}>
              <Button
                variant="primary"
                type="submit"
                className="fw-bold w-100 mb-1 mb-md-0"
                disabled={isLoading}
              >
                  {isLoading ? (
                    <>
                      <SpinnerButton /> <span className="me-2"> Loading...</span>
                    </>
                  ) : (
                    "Agree & Create account"
                  )}
              </Button>
            </Col>
          </Row>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default CreateAccountForm;
