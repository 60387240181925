// Import react and router DOM
import React from "react";

//Import components
import CreateAccountForm from "../../components/createAccount/createAccount";

//Import states, contexts and CSS
import { Col, Row, Container } from "react-bootstrap";
import styles from "./createAccountPage.module.scss";

const CreateAccountPage = () => {
  return (
    <Container className={`${styles["create-account-container"]} mt-5`} style={{marginBottom:"200px"}}>
      <Row>
        <Col xs={0} md={2} lg={4}></Col>
        <Col xs={12} md={8} lg={4}>
          <CreateAccountForm />
        </Col>
        <Col xs={0} md={2} lg={4}></Col>
      </Row>
    </Container>
  );
};

export default CreateAccountPage;
