
const ExtractDateTimeFromString = (dateString) => {
    const date = new Date(dateString);
    const datePart = date.toISOString().split("T")[0];
    const hours = String(date.getUTCHours()).padStart(2, "0");
    const minutes = String(date.getUTCMinutes()).padStart(2, "0");
    const timeString = `${hours}:${minutes}`;

    return `${datePart} - ${timeString}`;
  }

  
export default ExtractDateTimeFromString;