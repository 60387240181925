// Import react and router DOM
import React, { useState, useContext, useEffect } from "react";

//Import components

//Import states, contexts and CSS
import Form from "react-bootstrap/Form";
import productionSheetContext from "../../../../store/productionSheetContext";
import styles from "./PriceTableRow.module.scss";

const PriceTableRow = (props) => {
  const { index, costLabel, costName, setTotalCostTable } =
    props;
  const { newProductionSheet, setNewProductionSheet } = useContext(
    productionSheetContext
  );

  const [cost, setCost] = useState(newProductionSheet.costs[costName].cost);
  const [unit, setUnit] = useState(newProductionSheet.costs[costName].unit);
  const [quantityValue, setQuantityValue] = useState();
  const [switchAttivato, setSwitchAttivato] = useState(newProductionSheet.costs[costName].switchActive);
  const [totalCost, setTotalCost] = useState(0);


  const getDynamicValue = () => {
 
    switch (unit) {
      case "2":
        return newProductionSheet.netProductionWeight;
      case "3":

        return newProductionSheet.grossProductionWeight;
      case "4":
        return newProductionSheet.quantity;
      case "5":
        return newProductionSheet.colorsNumber;
      default:
        return 0;
    }
  };
  
  useEffect(() => {
    setCost(newProductionSheet.costs[costName].cost);
    setUnit(newProductionSheet.costs[costName].unit);
    setSwitchAttivato(newProductionSheet.costs[costName].switchActive);
  }, [newProductionSheet, costName]);

  useEffect(() => {
    const fetchData = () => {
      if (switchAttivato) {
        const dynamicValue = getDynamicValue(unit);
        setQuantityValue(dynamicValue);
        setTotalCost(dynamicValue * cost);
      } else {
        setQuantityValue(0);
        setTotalCost(0);
      }
    };

    fetchData();
  }, [unit, cost, switchAttivato, newProductionSheet]);
  
useEffect(() => {
  setTotalCostTable(totalCost);
}, [totalCost]);

useEffect(() => {
  if (switchAttivato !== newProductionSheet.costs[costName].switchActive) {
    setSwitchAttivato(newProductionSheet.costs[costName].switchActive);
  }
}, [newProductionSheet, costName, switchAttivato]);

const handleSwitchChange = () => {
  const newSwitchAttivato = !switchAttivato;
  setSwitchAttivato(newSwitchAttivato);
  
  setNewProductionSheet((prevData) => ({
    ...prevData,
    costs: {
      ...prevData.costs,
      [costName]: {
        ...prevData.costs[costName],
        switchActive: newSwitchAttivato,
      },
    },
  }));
};


const handleCostChange = (event) => {
  const newCost = event.target.value;
  setNewProductionSheet((prevData) => ({
    ...prevData,
    costs: {
      ...prevData.costs,
      [costName]: {
        ...prevData.costs[costName],
        cost: newCost,
      },
    },
  }));
};

const handleUnitChange = (event) => {
  const newUnit = event.target.value;
  const dynamicValue = getDynamicValue(newUnit);
  setUnit(newUnit);
  setQuantityValue(dynamicValue);
  setNewProductionSheet((prevData) => ({
    ...prevData,
    costs: {
      ...prevData.costs,
      [costName]: {
        ...prevData.costs[costName],
        unit: newUnit,
        
      },
    },
  }));
};


  return (
    <tr className={styles.priceTableRow}>
      <td>
        <Form.Switch
          type="switch"
          checked={switchAttivato}
          onChange={handleSwitchChange}
        />
      </td>
      <td>{index + 1}</td>
      <td>{costLabel}</td>
      <td>
        {switchAttivato ? (
          <Form.Group>
            <Form.Select onChange={handleUnitChange} value={unit}>
              <option value="1">Select an Option</option>
              <option value="2">Net Production Weight</option>
              <option value="3">Gross Production Weight</option>
              <option value="4">Quantity</option>
              <option value="5">Number of colors of Printing</option>
            </Form.Select>
          </Form.Group>
        ) : (
          <></>
        )}
      </td>
      <td>
        {switchAttivato ? (
          <Form.Group>
            <Form.Control
            className="text-end"
              type="text"
              placeholder="Value"
              disabled
              readOnly
              value={quantityValue}
            />
          </Form.Group>
        ) : (
          <></>
        )}
      </td>
      <td>
        {switchAttivato ? (
          <Form.Group>
            <Form.Control
            className="text-end"
              type="number"
              placeholder="cost"
              name="cost"
              onChange={handleCostChange}
              value={cost}
            />
          </Form.Group>
        ) : (
          <></>
        )}
      </td>
      <td>
        {switchAttivato ? (
          <Form.Control
            className="text-end"
            type="text"
            placeholder="Total Cost"
            value={totalCost}
          />
        ) : (
          <></>
        )}
      </td>
    </tr>
  );
};

export default PriceTableRow;
