// Import react and router DOM
import React from "react";
import { HddStack } from "react-bootstrap-icons";

//Import states, contexts and CSS
import Spinner from 'react-bootstrap/Spinner';
import Stack from 'react-bootstrap/Stack';


const SpinnerButton = () => {

    return (

        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
    )
}

const SpinnerGeneric = () => {
    return (
      <Stack className=" d-flex align-items-center justify-content-center">
        <Spinner animation="border" />
      </Stack>
    );
  }


export { SpinnerButton, SpinnerGeneric }