// Import react and router DOM
import React from "react";

// Import components

// Import states, contexts and CSS
import { Col, Row, Container } from "react-bootstrap";
import styles from "./softwarePage.module.scss";

const SoftwarePage = () => {
    return (
      <Container className={`${styles["login-container"]} my-5`}>
        <Row>
          <Col xs={0} md={2} lg={3}></Col>
          <Col xs={12} md={8} lg={6}>
            {/* Content Goes Here */}
            <h1 className="mb-4 text-center">Optimize Your Production Process with Smart Bag Pro</h1>
            <div className="mb-5">
              <h2>Streamline Production Sheet Creation</h2>
              <p className="text-justify">
                Smart Bag Pro simplifies the creation of production sheets, guiding users through the process and automating repetitive tasks. By providing customizable templates, our platform ensures swift and accurate production sheet generation.
              </p>
            </div>
            <div className="mb-5">
              <h2>Precise Cost Calculation</h2>
              <p className="text-justify">
                Smart Bag Pro's advanced algorithms accurately calculate production costs, taking into account material prices, labor costs, and other expenses. With detailed cost breakdowns, businesses can make informed decisions to optimize profitability.
              </p>
            </div>
            <div>
              <h2>Experience the Future of Bag Manufacturing</h2>
              <p className="text-justify">
                Discover how Smart Bag Pro revolutionizes bag manufacturing by streamlining processes and enhancing cost efficiency. Take your production to the next level with our innovative software solution.
              </p>
            </div>
          </Col>
          <Col xs={0} md={2} lg={3}></Col>
        </Row>
      </Container>
    );
  };
  

export default SoftwarePage;
