// Import react and router DOM
import React, { useCallback, useContext, useEffect } from "react";

//Import components

//Import states, contexts and CSS
import { Card, Form } from "react-bootstrap";
import InputGroup from "react-bootstrap/InputGroup";
import productionSheetContext from "../../../store/productionSheetContext.jsx";

const ExtrusionDetailsForm = () => {
  const { newProductionSheet, setNewProductionSheet } = useContext(
    productionSheetContext
  );

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewProductionSheet((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const calculateReelBand = () => {
    let reelBand;
    const widthDevelopment =
    parseFloat(newProductionSheet.width) +
    parseFloat(newProductionSheet.sideGussetOpen );

  const heightDevelopment =
    parseFloat(newProductionSheet.height) +
    parseFloat(Number(newProductionSheet.upperFlapOpen)) +
    parseFloat(newProductionSheet.bottomGussetOpen / 2);

    if (newProductionSheet.tracksDirection === "1") {
      reelBand =
        parseFloat(widthDevelopment) *
        parseFloat(newProductionSheet.tracksNumber);
    } else {
      reelBand =
        parseFloat(heightDevelopment) *
        parseFloat(newProductionSheet.tracksNumber);
    }
    return reelBand;
  };
  const calculateWeight = () => {
    const widthDevelopment =
      parseFloat(newProductionSheet.width) +
      parseFloat(newProductionSheet.sideGussetOpen);

    const heightDevelopment =
      parseFloat(newProductionSheet.height) +
      parseFloat(Number(newProductionSheet.upperFlapOpen)) +
      parseFloat(newProductionSheet.bottomGussetOpen / 2);

    const weightCalc = parseFloat(
      widthDevelopment *
        heightDevelopment *
        2 *
        parseFloat(newProductionSheet.thickness / 10000) *
        parseFloat(newProductionSheet.materialSpecificWeight)
    ).toFixed(2);

    const netProductionWeight = parseFloat(
      (weightCalc * Number(newProductionSheet.quantity)) / 1000
    ).toFixed(2);

    const grossProductionWeight = parseFloat(
      netProductionWeight *
        (1 + Number(newProductionSheet.outputTolerance) / 100)
    ).toFixed(2);

    const toleranceWeight = parseFloat(
      grossProductionWeight - netProductionWeight
    ).toFixed(2);

    setNewProductionSheet((prevData) => ({
      ...prevData,
      weight: weightCalc,
      netProductionWeight: netProductionWeight,
      grossProductionWeight: grossProductionWeight,
      toleranceWeight: toleranceWeight,
    }));
  };

  const calculateProductionMetrics = () => {
    const reelBand = calculateReelBand();
    const meterWeightRatio = parseFloat(
      reelBand *
        100 *
        2 *
        parseFloat(newProductionSheet.thickness / 10000) *
        parseFloat(newProductionSheet.materialSpecificWeight)
    ).toFixed(2);

    const grossProductionMeters = parseFloat(
      (newProductionSheet.grossProductionWeight * 1000) / meterWeightRatio
    ).toFixed(2);

    setNewProductionSheet((prevData) => ({
      ...prevData,
      meterWeightRatio,
      grossProductionMeters,
      reelBand,
    }));
  };

  useEffect(() => {
    calculateReelBand();
  }, [
    newProductionSheet.tracksDirection,
    newProductionSheet.width,
    newProductionSheet.height,
    newProductionSheet.tracksNumber,
  ]);

  useEffect(() => {
    calculateWeight();
  }, [
    newProductionSheet.width,
    newProductionSheet.height,
    newProductionSheet.sideGussetOpen,
    newProductionSheet.upperFlapOpen,
    newProductionSheet.bottomGussetOpen,
    newProductionSheet.thickness,
    newProductionSheet.materialSpecificWeight,
    newProductionSheet.quantity,
    newProductionSheet.outputTolerance,
  ]);

  useEffect(() => {
    calculateProductionMetrics();

  }, [
    newProductionSheet.grossProductionWeight,
    newProductionSheet.tracksDirection,
    newProductionSheet.tracksNumber,
  ]);

  return (
    <>
      <Card className={"mb-5"}>
        <Card.Header>
          <Card.Title>Extrusion details</Card.Title>
        </Card.Header>
        <Card.Body>
          <InputGroup controlId="tracksNumber" className={"mb-2 d-flex"}>
            <Form.Label className={"w-50"}>Tracks number</Form.Label>
            <Form.Control
            className="text-end"
              type="number"
              placeholder="Tracks number"
              name="tracksNumber"
              value={newProductionSheet.tracksNumber}
              onChange={handleChange}
            ></Form.Control>
            <InputGroup.Text>N</InputGroup.Text>
          </InputGroup>
          <InputGroup
            controlId="tracksDirection"
            className={"mb-2 d-flex align-items-center"}
          >
            <Form.Label className={"w-50"}>Tracks direction</Form.Label>
            <Form.Select
              aria-label="tracksDirection"
              onChange={handleChange}
              name="tracksDirection"
              value={newProductionSheet.tracksDirection}
            >
              <option value="1">Vertical</option>
              <option value="2">Horizontal</option>
            </Form.Select>
          </InputGroup>
          <InputGroup controlId="reelBand" className={"mb-2 d-flex"}>
            <Form.Label className={"w-50"}>Reel band</Form.Label>
            <Form.Control
            className="text-end"
              type="number"
              placeholder="Reel band"
              name="reelBand"
              value={newProductionSheet.reelBand}
              disabled
              readOnly
            ></Form.Control>
            <InputGroup.Text>Cm</InputGroup.Text>
          </InputGroup>
          <InputGroup controlId="meterWeightRatio" className={"mb-2 d-flex"}>
            <Form.Label className={"w-50"}>Meter/Weight Ratio</Form.Label>
            <Form.Control
            className="text-end"
              type="number"
              placeholder="Meter Weight Ratio"
              name="meterWeightRatio"
              value={newProductionSheet.meterWeightRatio}
              disabled
              readOnly
            ></Form.Control>
            <InputGroup.Text>Gr</InputGroup.Text>
          </InputGroup>
          <InputGroup
            controlId="grossProductionMeters"
            className={"mb-2 d-flex"}
          >
            <Form.Label className={"w-50"}>Gross Production Meters</Form.Label>
            <Form.Control
            className="text-end"
              type="number"
              placeholder="Gross Production Meters"
              name="grossProductionMeters"
              value={newProductionSheet.grossProductionMeters}
              disabled
              readOnly
            ></Form.Control>
            <InputGroup.Text>Mtl</InputGroup.Text>
          </InputGroup>
        </Card.Body>
      </Card>
    </>
  );
};

export default ExtrusionDetailsForm;
