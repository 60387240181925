// Import react and router DOM
import React, { useState } from "react";
import { Link } from "react-router-dom";

// Import bootstrap components
import { Button } from "react-bootstrap";

// Import Ag-Grid styles and components
import { PencilFill, Files, Trash3 } from "react-bootstrap-icons";
import DeleteModal from "./modals/deleteModal";
import CopyModal from "./modals/copyModal";
import ButtonGroup from "react-bootstrap/ButtonGroup";


// Import custom styles and context
import { notifyError,notifySuccess } from "../../toastify/toastify";

// import styles from "./productionSheetTableRow.module.scss";

const ProductionSheetTableRow = ({ id, reloadData, setReloadData }) => {
  const [isLoading, setIsLoading] = useState(false);
  // Copy Modal
  const [showCopyModal, setShowCopyModal] = useState(false);

  const handleCloseCopyModal = () => {
    setReloadData((prev) => !prev);
    setShowCopyModal(false)};
  const handleShowCopyModal = () => setShowCopyModal(true);

  // Delete Modal
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleDeleteShowModal = () => 

    setShowDeleteModal(true);
  const handleDeleteCloseModal = () => {
    setReloadData((prev) => !prev);
    setShowDeleteModal(false)};

  const token = localStorage.getItem("token");

  const handleConfirmCopy = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/productionSheets/${id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      const copySheet = await response.json();

      // Rimuovi le proprietà indesiderate
      const { _id, createdAt, updatedAt, __v, ...copySheetData } = copySheet;

      try {
        const postResponse = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/api/productionSheets/`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(copySheetData),
          }
        );

        if (!postResponse.ok) {
          throw new Error(postResponse.statusText);
        }

        const newSheet = await postResponse.json();
        const newSheetId = newSheet._id;
        localStorage.setItem("editProductionSheetId", newSheetId);
notifySuccess("Production Sheet copied successfully");

      } catch (error) {
notifyError("Error copying production sheet");
        setReloadData((prev) => !prev);
      }
    } catch (error) {
      notifyError("Error copying production sheet");
      setReloadData((prev) => !prev);
      
    } finally {
      setIsLoading(false);
    }
  };

  const handleConfirmDelete = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/productionSheets/${id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      // Close the modal
      handleDeleteCloseModal();
      setReloadData((prev) => !prev);
      notifySuccess("Production Sheet deleted successfully");
    } catch (error) {
notifyError("Error deleting production sheet");
      handleDeleteCloseModal();
      setReloadData((prev) => !prev);
    } finally {
      setIsLoading(false);
      setReloadData((prev) => !prev);
    }
  };

  const handleDelete = () => {
    // Show the confirmation modal
    handleDeleteShowModal();
  };

  const handleCopy = () => {
    handleShowCopyModal();
  };

  const handleEdit = () => {
    localStorage.setItem("editProductionSheetId", id);
  };

  return (
    <div className={`d-flex justify-content-center align-items-center p-0 m-0`}>
      <ButtonGroup>

      <Button
        variant="light"
        className={`d-flex justify-content-center align-items-center`}
        as={Link}
        to={`/dashboard/productionSheetPage/${id}`}
        // to= {`/dashboard/editProductionSheetPage/`}
        onClick={handleEdit}
      >
        <PencilFill />
      </Button>

      <Button
        variant="light"
        className={`d-flex justify-content-center align-items-center`}
        onClick={handleCopy}
      >
        <Files />
      </Button>

      <Button
        variant="light"
        className={`d-flex justify-content-center align-items-center`}
        onClick={handleDelete}
      >
        <Trash3 />
      </Button>
      </ButtonGroup>

      <DeleteModal
        show={showDeleteModal}
        handleClose={handleDeleteCloseModal}
        handleConfirm={handleConfirmDelete}
        message={`Are you sure you want to delete Production Sheet ID ${id}?`}
        isLoading={isLoading}
      />

      <CopyModal
        show={showCopyModal}
        handleClose={handleCloseCopyModal}
        handleConfirm={handleConfirmCopy}
        message={`Are you sure you want to copy Production Sheet ID ${id}?`}
        isLoading={isLoading}
      />
    </div>
  );
};

export default ProductionSheetTableRow;
