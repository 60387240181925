// Import react and router DOM
import React, { useContext } from "react";

//Import components

//Import states, contexts and CSS
// import styles from "./PrintDetailsForm.module.scss";
import productionSheetContext from "../../../store/productionSheetContext.jsx";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

const PrintDetailsForm = () => {
  const { newProductionSheet, setNewProductionSheet } = useContext(
    productionSheetContext
  );
  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewProductionSheet((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <>
      <Card className={"mb-5"}>
        <Card.Header>
          <Card.Title>Print details</Card.Title>
        </Card.Header>
        <Card.Body>
          <InputGroup controlId="colorsNumber" className={"mb-2 d-flex"}>
            <Form.Label className={"w-50"}>Colors number</Form.Label>
            <Form.Control
            className="text-end"
              type="number"
              placeholder="Colors number"
              name="colorsNumber"
              value={newProductionSheet.colorsNumber}
              onChange={handleChange}
            ></Form.Control>
            <InputGroup.Text>N</InputGroup.Text>
          </InputGroup>
        </Card.Body>
      </Card>
    </>
  );
};

export default PrintDetailsForm;
