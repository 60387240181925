// Import react and router DOM
import React, { useState } from "react";
import { Link } from "react-router-dom";

//Import components

//Import states, contexts and CSS
import { Container, Row } from "react-bootstrap";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";

import ListGroup from "react-bootstrap/ListGroup";
import {
  PlusCircle,
  BoxArrowLeft,
  FilePerson,
  ListUl,
  ChevronBarRight,
  BagHeartFill,
} from "react-bootstrap-icons";
import Offcanvas from "react-bootstrap/Offcanvas";
import Button from "react-bootstrap/Button";

import { useActiveUser } from "../../store/activeUserContext";

import styles from "./dashboardNav.module.scss";

const DashboardNavbarMobile = () => {
  const { logout } = useActiveUser(); // Usa useActiveUser invece di useContext(ActiveUserContext)

  return (
    <Navbar
      fixed="bottom"
      bg="dark"
      data-bs-theme="dark"
      style={{
        paddingBottom: "10px",
        position: "fixed",
        bottom: 0,
        width: "100%",
        zIndex: 1000, // Imposta uno z-index alto, puoi regolarlo secondo le tue esigenze
      }}
    >
      <Container>
        <Row className="w-100">
          <Nav className="p-0 me-auto d-flex justify-content-center align-items-center">
            <Nav.Link as={Link} to="/dashboard/productionSheetPage">
              <PlusCircle size={20} className="mx-4" />
            </Nav.Link>
            <Nav.Link as={Link} to="/dashboard/productionSheetList">
              <ListUl size={20} className="mx-4" />
            </Nav.Link>
            <Nav.Link as={Link} to="/profile">
              <FilePerson size={20} className="mx-4" />
            </Nav.Link>
            <Nav.Link to="/" onClick={logout}>
              <BoxArrowLeft size={20} className="mx-4" />
            </Nav.Link>
          </Nav>
        </Row>
      </Container>
    </Navbar>
  );
};

const DashboardNavbar = () => {
  const { logout } = useActiveUser(); // Usa useActiveUser invece di useContext(ActiveUserContext)
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <Navbar
      sticky="top"
      className={`${styles["dashboardNavbar"]} p-0 m-0`}
      variant="light"
      expand="lg"
    >
      <Container className="w-100 h-100 p-0">
        <Button
          variant="dark"
          onClick={handleShow}
          className={`${styles.buttonDashboardNavbar} py-5 px-1`}
        >
          <ChevronBarRight size={30} />
        </Button>
        <Offcanvas show={show} onHide={handleClose} bg="dark">
          <Offcanvas.Header closeButton>
            <Offcanvas.Title
              className={`${styles.tileDashboard} d-flex align-items-center`}
              as={Link}
              to="/about"
            >
              <BagHeartFill
                size={30}
                className={` me-2 align-self-center`}
              />
              <p className={`m-0 align-self-center`}>
                Smart Bag Pro
              </p>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <ListGroup className="list-group-flush">
              <ListGroup.Item
                action
                as={Link}
                to="/dashboard/productionSheetPage"
                className={`${styles.dashboardOffCanvasElement}`}
              >
                <PlusCircle size={20} className="me-2" />
                New Sheet
              </ListGroup.Item>
              <ListGroup.Item
                action
                as={Link}
                to="/dashboard/productionSheetList"
                className={`${styles.dashboardOffCanvasElement}`}
              >
                <ListUl size={20} className="me-2"  />
                Dashboard
              </ListGroup.Item>

              <ListGroup.Item action as={Link} to="/profile" className={`${styles.dashboardOffCanvasElement}`}>
                <FilePerson size={20} className="me-2" />
                Personal info
              </ListGroup.Item>

              <ListGroup.Item action to="/" onClick={logout} className={`${styles.dashboardOffCanvasElement}`}>
                <BoxArrowLeft size={20} className="me-2" />
                Log out
              </ListGroup.Item>
            </ListGroup>
          </Offcanvas.Body>
        </Offcanvas>
      </Container>
      {/* <Container className="w-100 h-100">
        <Card
          bg="dark"
          border="none"
          text={"dark" === "light" ? "dark" : "white"}
          className="w-100 h-100"
        >
          <Card.Header>
          </Card.Header>
          
          <ListGroup className="list-group-flush">
            <ListGroup.Item
              action
              as={Link}
              to="/dashboard/productionSheetPage"
            >
              <PlusCircle size={20} className="me-2" />
              New Sheet
            </ListGroup.Item>
            <ListGroup.Item
              action
              as={Link}
              to="/dashboard/productionSheetList"
            >
              <ListUl size={20} className="me-2" />
              Sheets List
            </ListGroup.Item>

            <ListGroup.Item action as={Link} to="/profile">
              <FilePerson size={20} className="me-2" />
              Personal info
            </ListGroup.Item>

            <ListGroup.Item action to="/" onClick={logout}>
              <BoxArrowLeft size={20} className="me-2" />
              Log out
            </ListGroup.Item>
          </ListGroup>

        </Card>
      </Container> */}
    </Navbar>
  );
};

export { DashboardNavbar, DashboardNavbarMobile };
