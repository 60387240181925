// Import react and router DOM
import React, { useState } from "react";

//Import components
import PriceTableRow from "./priceTableRow/PriceTableRow.jsx";

//Import states, contexts and CSS
import Form from "react-bootstrap/Form";
// import styles from "./PriceTable.module.scss";
import Table from "react-bootstrap/Table";

const PriceTable = () => {

  const priceCosts = [
    { key: "Material", value: "materialCost" },
    { key: "Extrusion", value: "extrusionCost" },
    { key: "printing", value: "printingCost" },
    { key: "Electricity", value: "electricityCost" },
    { key: "Cutting", value: "cuttingCost" },
    { key: "Transport", value: "transportCost" },
  ];

  const [totalCosts, setTotalCosts] = useState({
    materialCost: 0,
    extrusionCost: 0,
    printingCost: 0,
    electricityCost: 0,
    cuttingCost: 0,
    transportCost: 0,
  });

  const updateTotalCost = (costName, total) => {
    
    setTotalCosts((prevTotalCosts) => ({
      ...prevTotalCosts,
      [costName]: total,
    }));
  };


  return (
    <>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Active</th>
            <th>#</th>
            <th>Cost</th>
            <th>Based on</th>
            <th>Value</th>

            <th>Cost</th>
            <th>Total Cost</th>
          </tr>
        </thead>
        <tbody>
          {priceCosts.map((costElement, index) => (
            <PriceTableRow

              costLabel={costElement.key}
              costName={costElement.value}
              key={index}
              index={index}
              totalCostTable={totalCosts[costElement.value]}
              setTotalCostTable={(total) => updateTotalCost(costElement.value, total)}
            />
          ))}

          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>Total</td>
            <td>
              <Form.Group>
                <Form.Control
            className="text-end"
                  type="text"
                  placeholder="Total Cost"
                  disabled
                  readOnly
                  value={Object.values(totalCosts).reduce((acc, curr) => acc + curr, 0)}

                />
              </Form.Group>
            </td>
          </tr>
        </tbody>
      </Table>
    </>
  );
};

export default PriceTable;