// Import react and router DOM
import React from "react";
import { Link } from "react-router-dom";

//Import states, contexts and CSS
import {
  PersonCircle,
  BoxArrowLeft,
  FilePerson,
  ListUl,
  PlusCircle,
} from "react-bootstrap-icons";

import ButtonGroup from "react-bootstrap/ButtonGroup";
import styles from "./LoggedDropdown.module.scss";
import { useActiveUser } from "../../store/activeUserContext";
import NavDropdown from 'react-bootstrap/NavDropdown';

const LoggedDropdown = () => {
  const { activeUser, logout } = useActiveUser(); // Usa useActiveUser invece di useContext(ActiveUserContext)

  return activeUser ? (
    <>
      <NavDropdown
      
      as={ButtonGroup}
        align={{ lg: "start" }}
        title={
          <>
            {activeUser.firstName} <PersonCircle size={25} />
          </>
        }
        // id="dropdown-menu-align-responsive-2"
        key="down-centered"
        id="dropdown-button-drop-down-centered"
        drop="down-centered"
        className={`bg-none ${styles["loggedDropdown"]}`} 

      >
        <NavDropdown.Header className={styles.dropdownItem}>
          <h6>{activeUser.firstName + " " + activeUser.lastName}</h6>
        </NavDropdown.Header>
        <NavDropdown.Divider />

        <NavDropdown.Item as={Link} to="/profile" className={styles.dropdownItem}>
          <FilePerson size={20} /> Personal Info
        </NavDropdown.Item>

        <NavDropdown.Item
          as={Link}
          to="/dashboard/productionSheetPage"
          className={styles.dropdownItem}
        >
          <PlusCircle size={20} /> New Sheet
        </NavDropdown.Item>
        <NavDropdown.Item
          as={Link}
          to="/dashboard/productionSheetList"
          className={styles.dropdownItem}
        >
          <ListUl size={20} /> Dashboard
        </NavDropdown.Item>

        {activeUser.isAdmin && (
          <>
            <NavDropdown.Item as={Link} to="/admin" className="mb-5">
              Admin
            </NavDropdown.Item>
          </>
        )}
        <NavDropdown.Divider />

        <NavDropdown.Item
          as={Link}
          to="/"
          onClick={logout}
          className={styles.dropdownItem}
        >
          <BoxArrowLeft size={20} /> Log out
        </NavDropdown.Item>
      </NavDropdown>

      {/* <DropdownButton
      
      as={ButtonGroup}
        align={{ lg: "start" }}
        title={
          <>
            {activeUser.firstName} <PersonCircle size={25} />
          </>
        }
        // id="dropdown-menu-align-responsive-2"
        key="down-centered"
        id="dropdown-button-drop-down-centered"
        drop="down-centered"
        className={`bg-none ${styles["loggedDropdown"]}`} 

      >
        <Dropdown.Header className={styles.dropdownItem}>
          <h6>{activeUser.firstName + " " + activeUser.lastName}</h6>
        </Dropdown.Header>
        <Dropdown.Divider />

        <Dropdown.Item as={Link} to="/profile" className={styles.dropdownItem}>
          <FilePerson size={20} /> Personal Info
        </Dropdown.Item>

        <Dropdown.Item
          as={Link}
          to="/dashboard/productionSheetPage"
          className={styles.dropdownItem}
        >
          <PlusCircle size={20} /> New Sheet
        </Dropdown.Item>
        <Dropdown.Item
          as={Link}
          to="/dashboard/productionSheetList"
          className={styles.dropdownItem}
        >
          <ListUl size={20} /> Sheets List
        </Dropdown.Item>

        {activeUser.isAdmin && (
          <>
            <Dropdown.Item as={Link} to="/admin" className="mb-5">
              Admin
            </Dropdown.Item>
          </>
        )}
        <Dropdown.Divider />

        <Dropdown.Item
          as={Link}
          to="/"
          onClick={logout}
          className={styles.dropdownItem}
        >
          <BoxArrowLeft size={20} /> Log out
        </Dropdown.Item>
      </DropdownButton> */}
    </>
  ) : null;
};

export default LoggedDropdown;
