// Import react and router DOM
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

// Import components
import { SpinnerButton } from "../../utils/spinners";
import { notifySuccess, notifyError } from "../toastify/toastify";

//Import states, contexts and CSS
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Card from "react-bootstrap/Card";
import { GoogleLoginButton } from "react-social-login-buttons";

// import styles from "./LoginForm.module.scss";
import { useActiveUser } from "../../store/activeUserContext";

const LoginForm = () => {
  const { login } = useActiveUser();

  const emptyLoginForm = { email: "", password: "" };
  const [loginForm, setLoginForm] = useState(emptyLoginForm);
  const [validated, setValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const handleGoogleLogin = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get("token");
      const userId = urlParams.get("userId");
      // Controlla se l'utente è già autenticato
      const storedUserId = localStorage.getItem("userId");
      const storedToken = localStorage.getItem("token");

      if (token && userId && (!storedUserId || !storedToken)) {
        // Memorizza l'ID e il token nel localStorage
        localStorage.setItem("userId", userId);
        localStorage.setItem("token", token);

        // Esegui la richiesta al backend per ottenere i dati dell'utente
        try {
          const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/api/users/${userId}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );

          if (!response.ok) {
            throw new Error("Error connecting to the server");
          }

          const user = await response.json();
          // Login dell'utente
          login(user, userId, token);
          notifySuccess("Logged in successfully");
          navigate("/dashboard/productionSheetList");
        } catch (error) {
          notifyError("Error connecting to the server");
        }
      }
    };

    handleGoogleLogin();
  }, [login, navigate]);

  const handleChange = (e) => {
    setLoginForm({ ...loginForm, [e.target.name]: e.target.value });
  };

  const submitValidForm = async (e) => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/login`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(loginForm),
        }
      );

      if (!response.ok) {
        throw new Error("Your email or password is incorrect");
      }

      const { id, token } = await response.json();

      if (response.ok) {
        try {
          const response2 = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/api/users/${id}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );

          if (!response2.ok) {
            throw new Error("Error connecting to the server");
          }

          const user = await response2.json();
          login(user, id, token);
          notifySuccess("Login successful");
          navigate("/dashboard/productionSheetList");
        } catch (error) {
          console.error(error);
          notifyError("Error fetching user data");
        }
      }
    } catch (error) {
      console.error(error);
      notifyError("Your email or password is incorrect");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    form.checkValidity() === false
      ? event.stopPropagation()
      : submitValidForm(event);

    setValidated(true);
  };

  return (
    <>
      <Card className={"my-5"}>
        <Card.Body>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <h2 className={"mt-1 mb-1"}>Log in to your account</h2>
            <p className={"mb-5"}>
              Don't have a Smart Bag Pro account?{" "}
              <Link to="/createaccount">Sign up</Link>
            </p>

            <Form.Group controlId="formBasicEmail" className={"mb-2"}>
              <FloatingLabel label="Email address">
                <Form.Control
                  name="email"
                  type="email"
                  placeholder="Email address"
                  value={loginForm.email}
                  onChange={handleChange}
                  required
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>

                <Form.Control.Feedback type="invalid">
                  Please provide a valid email.
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
            <Form.Group controlId="formBasicPassword">
              <FloatingLabel label="Password">
                <Form.Control
                  name="password"
                  type="password"
                  placeholder="Password"
                  value={loginForm.password}
                  onChange={handleChange}
                  required
                />

                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>

                <Form.Control.Feedback type="invalid">
                  Please provide a valid password.
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
            <Form.Check
              type="checkbox"
              label="Remember me"
              className={"mb-5"}
            />
            <Container>
              <Row className={"mb-5"}>
                <Col xs={12}>
                  <Button
                    variant="primary"
                    type="submit"
                    className="w-100 mb-1 mb-md-0 fw-bold"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <>
                        <SpinnerButton />{" "}
                        <span className="me-2"> Logging in...</span>
                      </>
                    ) : (
                      "Log in"
                    )}
                  </Button>
                </Col>
              </Row>
              <Row className={"mb-3"}>
                <Col xs={12}>
                  <p className="text-center mb-1 mb-md-0">
                    Or log in with your social account
                  </p>
                </Col>
              </Row>

              <Row>
                <Col xs={12}>
                  <GoogleLoginButton
                    onClick={() => {
                      window.location.assign(
                        "http://localhost:3030/api/users/oauth-google"
                      );
                    }}
                  />
                </Col>
              </Row>
            </Container>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};

export default LoginForm;
