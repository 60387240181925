// Import react and router DOM
import React, { useContext } from "react";

//Import components

//Import states, contexts and CSS
// import styles from "./ResultsForm.module.scss";
import productionSheetContext from "../../../store/productionSheetContext.jsx";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

const ResultsForm = () => {
  const { newProductionSheet } = useContext(
    productionSheetContext
  );

  return (
    <>
      <Card className={"mb-5"}>
        <Card.Header>
          {" "}
          <Card.Title>Results</Card.Title>
        </Card.Header>
        <Card.Body>
          <InputGroup
            controlId="weight"
            className={"mb-2 d-flex align-items-center"}
          >
            <Form.Label className={"me-3 w-50"}>
              Product weight each
            </Form.Label>
            <Form.Control
            className="text-end"
              type="number"
              placeholder="Product weight"
              name="weight"
              value={newProductionSheet.weight}
              disabled
              readOnly
            ></Form.Control>
                        <InputGroup.Text>Gr</InputGroup.Text>

          </InputGroup>
          <hr />
          <InputGroup
            controlId="netProductionWeight"
            className={"mb-2 d-flex align-items-center"}
          >
            <Form.Label className={"me-3 w-50"}>
              Net production weight
            </Form.Label>
            <Form.Control
            className="text-end"
              type="number"
              placeholder="Net production weight"
              name="netProductionWeight"
              value={newProductionSheet.netProductionWeight}
              disabled
              readOnly
            ></Form.Control>
            <InputGroup.Text>Kg</InputGroup.Text>
          </InputGroup>
          <InputGroup
            controlId="toleranceWeight"
            className={"mb-2 d-flex align-items-center"}
          >
            <Form.Label className={"me-3 w-50"}>Tolerance weight</Form.Label>
            <Form.Control
            className="text-end"
              type="number"
              placeholder="Tolerance weight"
              name="toleranceWeight"
              value={newProductionSheet.toleranceWeight}
              disabled
              readOnly
            ></Form.Control>
            <InputGroup.Text>Kg</InputGroup.Text>
          </InputGroup>
          <InputGroup
            controlId="grossProductionWeight"
            className={"mb-2 d-flex align-items-center"}
          >
            <Form.Label className={"me-3 w-50"}>
              Gross production weight
            </Form.Label>
            <Form.Control
            className="text-end"
              type="number"
              placeholder="Gross production weight"
              name="grossProductionWeight"
              value={newProductionSheet.grossProductionWeight}
              disabled
              readOnly
            ></Form.Control>
            <InputGroup.Text>Kg</InputGroup.Text>
          </InputGroup>
          <hr />
          <InputGroup
            controlId="reelBand"
            className={"mb-2 d-flex align-items-center"}
          >
            <Form.Label className={"me-3 w-50"}>Reel band</Form.Label>
            <Form.Control
            className="text-end"
              type="number"
              placeholder="Reel band"
              name="reelBand"
              value={newProductionSheet.reelBand}
              disabled
              readOnly
            ></Form.Control>
            <InputGroup.Text>Cm</InputGroup.Text>
          </InputGroup>
          <InputGroup
            controlId="meterWeightRatio"
            className={"mb-2 d-flex align-items-center"}
          >
            <Form.Label className={"me-3 w-50"}>Meter/Weight Ratio</Form.Label>
            <Form.Control
            className="text-end"
              type="number"
              placeholder="Meter Weight Ratio"
              name="meterWeightRatio"
              value={newProductionSheet.meterWeightRatio}
              disabled
              readOnly
            ></Form.Control>
            <InputGroup.Text>Gr</InputGroup.Text>
          </InputGroup>
          <InputGroup
            controlId="grossProductionMeters"
            className={"mb-2 d-flex align-items-center"}
          >
            <Form.Label className={"me-3 w-50"}>Gross production meters</Form.Label>
            <Form.Control
            className="text-end"
              type="number"
              placeholder="Gross Production Meters"
              name="grossProductionMeters"
              value={newProductionSheet.grossProductionMeters}
              disabled
              readOnly
            ></Form.Control>
                                                <InputGroup.Text>Mtl</InputGroup.Text>

          </InputGroup>
        </Card.Body>
      </Card>
    </>
  );
};

export default ResultsForm;
