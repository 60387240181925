// Import react and router DOM
import React from "react";

//Import components
import ProfileData from "../../components/profileData/profileData";
import {
  DashboardNavbar,
  DashboardNavbarMobile,
} from "../../components/dashboardNav/dashboardNavbar";

//Import states, contexts and CSS
import { Col, Row } from "react-bootstrap";
// import styles from "./profilePage.module.scss";
import Card from "react-bootstrap/Card";

const ProfilePage = () => {
  return (
    <>
      <Row className="my-4">
        <Col lg={1} className="d-none d-lg-block">
          <DashboardNavbar />
        </Col>
        <Col xs={12} lg={10}>
          <Card className="mb-2" border="dark">
            <Card.Header>
              <h1 className={" text-center"}>Profile Data</h1>
            </Card.Header>
          </Card>
          <ProfileData />
        </Col>
        <Col  lg={1}></Col>
      </Row>
      <Row className="my-4">
        <Col xs={12} className="d-lg-none">
          <DashboardNavbarMobile />
        </Col>
      </Row>
    </>
  );
};

export default ProfilePage;
