// Import react and router DOM
import React, { useContext } from "react";

//Import components

//Import states, contexts and CSS
// import styles from "./BagDetailsForm.module.scss";
import productionSheetContext from "../../../store/productionSheetContext.jsx";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

const BagDetailsForm = () => {
  const { newProductionSheet, setNewProductionSheet } = useContext(
    productionSheetContext
  );

  const handleChange = (e) => {
    const { name, value } = e.target;

    setNewProductionSheet((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <>
      <Card className={"mb-5"}>
        <Card.Header>
          {" "}
          <Card.Title>Bag details</Card.Title>
        </Card.Header>
        <Card.Body>
          <InputGroup controlId="bagType" className={"mb-2"}>
            <Form.Select aria-label="Select product type" name="bagType">
              <option>Select product type</option>
              <option value="1">Polybag</option>
              <option value="2">Paper Bag</option>
            </Form.Select>
          </InputGroup>
          <InputGroup
            controlId="width"
            className={"mb-2 d-flex align-items-center"}
          >
            <Form.Label className={"w-50"}>Width</Form.Label>
            <Form.Control
              className="text-end"
              required
              type="number"
              placeholder="Product width"
              name="width"
              value={newProductionSheet.width}
              onChange={handleChange}
            ></Form.Control>
            <InputGroup.Text>Cm</InputGroup.Text>
          </InputGroup>
          <InputGroup
            controlId="height"
            className={"mb-2 d-flex align-items-center"}
          >
            <Form.Label className={"w-50"}>Height</Form.Label>
            <Form.Control
              className="text-end"
              type="number"
              placeholder="Product height"
              name="height"
              value={newProductionSheet.height}
              onChange={handleChange}
            ></Form.Control>
            <InputGroup.Text>Cm</InputGroup.Text>
          </InputGroup>
          <InputGroup
            controlId="thickness"
            className={"mb-2 d-flex align-items-center"}
          >
            <Form.Label className={"w-50"}>Thickness</Form.Label>
            <Form.Control
              className="text-end"
              type="number"
              placeholder="Product thickness"
              name="thickness"
              value={newProductionSheet.thickness}
              onChange={handleChange}
            ></Form.Control>
            <InputGroup.Text>My</InputGroup.Text>
          </InputGroup>
          <InputGroup
            controlId="materialSecificWeight"
            className={"mb-2 d-flex align-items-center"}
          >
            <Form.Label className={"w-50"}>Material specific weight</Form.Label>
            <Form.Control
              className="text-end"
              type="number"
              placeholder="Material specific weight"
              name="materialSpecificWeight"
              value={newProductionSheet.materialSpecificWeight}
              onChange={handleChange}
            ></Form.Control>
            <InputGroup.Text>{"| | |"}</InputGroup.Text>
          </InputGroup>
          <InputGroup
            controlId="bottomGussetOpen"
            className={"mb-2 d-flex align-items-center"}
          >
            <Form.Label className={"w-50"}>Bottom gusset open</Form.Label>
            <Form.Control
              className="text-end"
              type="number"
              placeholder="Buttom gusset open"
              name="bottomGussetOpen"
              value={newProductionSheet.bottomGussetOpen}
              onChange={handleChange}
            ></Form.Control>
            <InputGroup.Text>Cm</InputGroup.Text>
          </InputGroup>

          <InputGroup
            controlId="sideGussetOpen"
            className={"mb-2 d-flex align-items-center"}
          >
            <Form.Label className={"w-50"}>Side gusset open</Form.Label>
            <Form.Control
              className="text-end"
              type="number"
              placeholder="Side gusset open"
              name="sideGussetOpen"
              value={newProductionSheet.sideGussetOpen}
              onChange={handleChange}
            ></Form.Control>
            <InputGroup.Text>Cm</InputGroup.Text>
          </InputGroup>
          <InputGroup
            controlId="upperFlapOpen"
            className={"mb-2 d-flex align-items-center"}
          >
            <Form.Label className={"w-50"}>Upper flap open</Form.Label>
            <Form.Control
              className="text-end"
              type="number"
              placeholder="Upper flap open"
              name="upperFlapOpen"
              value={newProductionSheet.upperFlapOpen}
              onChange={handleChange}
            ></Form.Control>
            <InputGroup.Text>Cm</InputGroup.Text>
          </InputGroup>

          <InputGroup
            controlId="cuttingDie"
            className={"mb-2 d-flex align-items-center"}
          >
            <Form.Label className={"w-50"}>Cutting die</Form.Label>
            <Form.Control
              className="text-end"
              type="number"
              placeholder="Cutting die"
              name="cuttingDie"
              value={newProductionSheet.cuttingDie}
              onChange={handleChange}
            ></Form.Control>
            <InputGroup.Text> % </InputGroup.Text>
          </InputGroup>
        </Card.Body>
      </Card>
    </>
  );
};

export default BagDetailsForm;
