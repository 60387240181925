// Import react and router DOM
import React from "react";
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";

// Import Bootstrap components
import { Container, Row, Col } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import Stack from "react-bootstrap/Stack";
import Button from "react-bootstrap/Button";
import { BagHeartFill } from "react-bootstrap-icons";

// Import CSS
import styles from "./homepage.module.scss";

const HomePage = () => {
  const { ref: myRef, inView: myElementIsVisible } = useInView({
    triggerOnce: true,
  });

  const { ref: myRef2, inView: myElementIsVisible2 } = useInView({
    triggerOnce: true,
  });

  const { ref: myRef3, inView: myElementIsVisible3 } = useInView({
    triggerOnce: true,
  });

  return (
    <>
      <Stack direction="vertical">
        {/* Jumbotron Section */}
        <Row
          className={` ${styles["homeImageRow"]} m-0 justify-content-center align-items-center mb-5 w-100`}
        >
          <Col
            xs={12}
            lg={12}
            className={`${styles["homeImageCol"]} text-center h-100`}
          >
            <Image
              src="https://images.unsplash.com/photo-1473773508845-188df298d2d1?q=80&w=1374&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              fluid
              className={`${styles["homeImage"]} ${styles["image-show"]} w-100 m-0`}
            />
            <Container className="h-100">
              <Row className="justify-content-center align-items-center mt-5 h-100">
                <Col xs={0} lg={3}></Col>

                <Col xs={12} lg={6} className="h-100">
                  <div
                    className={`${styles["titleContainerDiv"]} ${styles["titleContainerDiv-show"]}`}
                  >
                    <BagHeartFill size={160} className="me-2" />
                    <h1 className={`text-center ${styles["homepageTitle"]}`}>
                      Smart Bag Pro
                    </h1>
                    <h2 className="text-center mb-5">
                      Software for Plastic & Paper Success
                    </h2>
                    <Button
                      className={`text-csenter ${styles["getStartedButton"]}`}
                      variant="warning"
                      as={Link}
                      to="/createaccount"
                    >
                      Get Started
                    </Button>
                  </div>
                </Col>
                <Col xs={0} lg={3}></Col>
              </Row>
            </Container>
          </Col>
        </Row>
        <Container className="my-3 d-none d-lg-block">
          <Row className={`mb-0`} style={{ height: "150px" }}>
            <div ref={myRef3}>
              <h1
                className={`text-center ${styles["payoff"]}  ${
                  myElementIsVisible3 ? styles["scale-in-bottom"] : ""
                }`}
              >
                Empowering Bag Manufacturing
                <br />
                for a Sustainable Future
              </h1>
            </div>
          </Row>
        </Container>
        {/* Plastic Bags Section */}
        <Container className="mb-5 d-none d-lg-block">
          <Row className={`mb-5`} style={{ height: "300px" }}>
            <Col xs={12} lg={6} style={{ position: "relative" }}>
              <div
                ref={myRef}
                className={`w-100 ${
                  myElementIsVisible ? styles["slide-right"] : ""
                } d-flex flex-column justify-content-center align-items-start gap-3 h-100 p-5 ${
                  styles["slideRightElement"]
                } `}
              >
                <h1>Plastic bags</h1>
                <h3>Calculate your production data the easy way!</h3>
                <p>
                  Smart bag pro was made to work for you. Revolutionize your
                  plastic bag production. Optimize production data and manage
                  costs seamlessly.
                </p>
              </div>
            </Col>
            <Col
              xs={12}
              lg={6}
              className="d-flex  justify-content-center align-items-center"
              style={{ position: "relative" }}
            >
              <Image
                ref={myRef}
                src="https://cdn.apartmenttherapy.info/image/upload/f_auto,q_auto:eco,c_fill,g_center,w_730,h_487/stock%2F2F24022d3871b39ac8e06ece0263d1749d0124418f"
                fluid
                className={`w-100 ${styles["slideLeftElement"]} ${
                  myElementIsVisible ? styles["slide-left"] : ""
                }`}
              />
            </Col>
          </Row>

          {/* Paper Bags Section */}
          <Row className={`mb-5`} style={{ height: "300px" }}>
            <Col xs={12} lg={6} style={{ position: "relative" }}>
              <Image
                ref={myRef2}
                src="https://www.neonpackaging.com.au/cdn/shop/articles/What-is-kraft-paper-01_355e0fae-7b11-4ec7-b5ce-d9c592ad81b0-160062.jpg?v=1682944944"
                fluid
                className={`w-100 ${styles["slideRightElement"]}  ${
                  myElementIsVisible2 ? styles["slide-right"] : ""
                }`}
              />
            </Col>
            <Col
              xs={12}
              lg={6}
              className="d-flex  justify-content-center align-items-center"
              style={{ position: "relative" }}
            >
              <div
                ref={myRef2}
                className={`w-100  ${
                  myElementIsVisible2 ? styles["slide-left"] : ""
                } d-flex flex-column justify-content-center align-items-start gap-3 h-100 p-5 ${
                  styles["slideLeftElement"]
                } `}
              >
                <h1>Paper Bags</h1>
                <h3>Elevate your packaging with eco-friendly solutions!</h3>
                <p>
                  Embrace sustainability with Smart Bag Pro's innovative paper
                  bag solutions. Discover eco-friendly production methods and
                  reduce your environmental impact.
                </p>
              </div>
            </Col>
          </Row>
        </Container>

        {/* Mobile section */}
        <Container className="my-3 d-lg-none">
          <Row className={`mb-0`} style={{ height: "150px" }}>
            <h1
              className={`text-center ${styles["payoff"]} ${
                myElementIsVisible3 ? styles["scale-in-bottom"] : ""
              }`}
            >
              Empowering Bag Manufacturing
              <br />
              for a Sustainable Future
            </h1>
          </Row>
        </Container>
        <Container className="mb-5 d-lg-none">
          <Row className={`mb-2`}>
            <Col xs={12} className="mb-2">
              <div
                className={`w-100 d-flex flex-column justify-content-center align-items-center gap-3 h-100 p-2 `}
              >
                <h1>Plastic bags</h1>
                <h3>Calculate your production data the easy way!</h3>
                <p>
                  Smart bag pro was made to work for you. Revolutionize your
                  plastic bag production. Optimize production data and manage
                  costs seamlessly.
                </p>
              </div>
            </Col>
            <Col
              xs={12}
              lg={6}
              className="d-flex  justify-content-center align-items-center mb-5"
              style={{ position: "relative" }}
            >
              <Image
                src="https://cdn.apartmenttherapy.info/image/upload/f_auto,q_auto:eco,c_fill,g_center,w_730,h_487/stock%2F2F24022d3871b39ac8e06ece0263d1749d0124418f"
                fluid
                className={`w-100 `}
              />
            </Col>

            {/* Paper Bags Section */}
            <Col xs={12} className="mb-2">
              <div
                className={`w-100 d-flex flex-column justify-content-center align-items-center gap-3 h-100 p-2 `}
              >
                <h1>Paper Bags</h1>
                <h3>Elevate your packaging with eco-friendly solutions!</h3>
                <p>
                  Embrace sustainability with Smart Bag Pro's innovative paper
                  bag solutions. Discover eco-friendly production methods and
                  reduce your environmental impact.
                </p>
              </div>
            </Col>
            <Col xs={12} className="mb-5">
              <Image
                src="https://www.neonpackaging.com.au/cdn/shop/articles/What-is-kraft-paper-01_355e0fae-7b11-4ec7-b5ce-d9c592ad81b0-160062.jpg?v=1682944944"
                fluid
                className={`w-100 `}
              />
            </Col>
          </Row>
        </Container>
      </Stack>
    </>
  );
};

export default HomePage;
