// Import react and router DOM
import React from "react";

//Import components
import LoginForm from "../../components/login/LoginForm";

//Import states, contexts and CSS
import { Col, Row, Container } from "react-bootstrap";
import styles from "./loginPage.module.scss";




const LoginPage = () => {



  return (
      
        <Container className={`${styles["login-container"]} my-5`}>

        <Row>
          <Col xs={0} md={2} lg={4}></Col>
          <Col xs={12}  md={8} lg={4}>
          <LoginForm />
          </Col>
          <Col xs={0} md={2} lg={4}></Col>
        </Row>
      </Container>
  );
};

export default LoginPage;
