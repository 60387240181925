// Import react and router DOM
import React, { useContext } from "react";

//Import components

//Import states, contexts and CSS
// import styles from "./ProductionDetailsForm.module.scss";
import productionSheetContext from "../../../store/productionSheetContext.jsx";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

const ProductionDetailsForm = () => {
  const { newProductionSheet, setNewProductionSheet } = useContext(
    productionSheetContext
  );

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewProductionSheet((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <>
      <Card className={"mb-5"}>
        <Card.Header>
          <Card.Title>Production details</Card.Title>
        </Card.Header>
        <Card.Body>
          <InputGroup
            controlId="quantity"
            className={"mb-2 d-flex align-items-center"}
          >
            <Form.Label className={"me-3 w-50"}>Quantity</Form.Label>
            <Form.Control
            className="text-end"
              type="number"
              placeholder="Quantity"
              name="quantity"
              value={newProductionSheet.quantity}
              onChange={handleChange}
            ></Form.Control>
            <InputGroup.Text>Pcs</InputGroup.Text>
          </InputGroup>
          <InputGroup
            controlId="outputTolerance"
            className={"mb-2 d-flex align-items-center"}
          >
            <Form.Label className={"me-3 w-50"}>Output Tolerance</Form.Label>
            <Form.Control
            className="text-end"
              type="number"
              placeholder="Output tolerance"
              name="outputTolerance"
              value={newProductionSheet.outputTolerance}
              onChange={handleChange}
            ></Form.Control>
            <InputGroup.Text>%</InputGroup.Text>
          </InputGroup>
        </Card.Body>
      </Card>
    </>
  );
};

export default ProductionDetailsForm;
