// Import react and router DOM
import React from "react";

//Import components
import UsersTable from "../../components/usersTable/UsersTable.jsx";

//Import states, contexts and CSS
import { Col, Row, Container } from "react-bootstrap";
import styles from "./adminPage.module.scss";
import { useActiveUser } from "../../store/activeUserContext.jsx";

const AdminPage = () => {
  const { activeUser } = useActiveUser();

  return (
    <div className={styles["admin-page-container"]}>
      <Container>
        <Row>
          <Col xs={12}>{activeUser ? <UsersTable /> : <div></div>}</Col>
        </Row>
      </Container>
    </div>
  );
};

export default AdminPage;
