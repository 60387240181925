// Import react and router DOM
const emptyProductionSheet = {
    bagType: "",
    userId: "",
    width: 0,
    height: 0,
    thickness: 0,
    materialSpecificWeight: 0,
    weight: 0,
    bottomGussetOpen: 0,
    sideGussetOpen: 0,
    upperFlapOpen: 0,
    quantity: 0,
    quantityUnit: "",
    cuttingDie: 0,
    netProductionWeight: "",
    grossProductionWeight: "",
    outputTolerance: 0,
    outputToleranceUnit: "",
    toleranceWeight: "",
    materialPrice: "",
    tracksDirection: "1",
    tracksNumber: 0,
    reelBand: "",
    meterWeightRatio: "",
    grossProductionMeters: "",
    colorsNumber: 0,
    costs: {
      materialCost: { cost: "", unit: "", value: "", switchActive: false },
      extrusionCost: { cost: "", unit: "" },
      printingCost: { cost: "", unit: "" },
      electricityCost: { cost: "", unit: "" },
      cuttingCost: { cost: "", unit: "" },
      transportCost: { cost: "", unit: "" },
    },
  };


  const emptyCreateAccountForm = {
    firstName: "",
    lastName: "",
    dateOfBirth: "",
    countryOfResidence: null,
    email: "",
    password: "",
  };

  export {emptyProductionSheet,emptyCreateAccountForm}