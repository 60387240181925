// Import react and router DOM
import React, { useState,useEffect } from "react";
import { Link } from "react-router-dom";

//Import components

//Import states, contexts and CSS
import { Container, Col, Row } from "react-bootstrap";
import styles from "./profileData.module.scss";
import Table from 'react-bootstrap/Table';


const ProfileData = () => {

  const token = localStorage.getItem("token");
  const id = localStorage.getItem("id");
  const [profileData, setProfileData] = useState({});
  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/users/${id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error("Errore nella connessione al server");
        }

        const user = await response.json();
        setProfileData(user);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData(); // Invoke the function

  }, [token, id]);



  return (
<Container className={"mb-4"}>      
      <Row>

        <Col xs={12} >
          <div className={"mt-5 mb-5"}>
            <Table striped bordered hover>
              <tbody>
                <tr>
                  <th scope="row">First Name</th>
                  <td>{profileData.firstName}</td>
                  <td>
                    <Link to={"/edit-profile"}>Edit</Link>
                  </td>
                </tr>
                <tr>
                  <th scope="row">Last Name</th>
                  <td>{profileData.lastName}</td>
                  <td>
                    <Link to={"/edit-profile"}>Edit</Link>
                  </td>
                </tr>
                <tr>
                  <th scope="row">Email</th>
                  <td> {profileData.email} </td>
                  <td>
                    <Link to={"/edit-profile"}>Edit</Link>
                  </td>
                </tr>
                <tr>
                  <th scope="row">Date of Birth</th>
                  <td>{profileData.dateOfBirth}</td>
                  <td>
                    <Link to={"/edit-profile"}>Edit</Link>
                  </td>
                </tr>
                <tr>
                  <th scope="row">Country Of Residence</th>
                  <td>{profileData.countryOfResidence}</td>
                  <td>
                    <Link to={"/edit-profile"}>Edit</Link>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
      </Container>

  );
};

export default ProfileData;