// Import react and router DOM
import React, {useContext} from "react";

//Import components

//Import states, contexts and CSS
import Table from 'react-bootstrap/Table';
import styles from "./UsersTable.module.scss";
import usersDataContext from "../../store/usersDataContext";



const UsersTable = () => {
  const { usersData } = useContext(usersDataContext);

  //FECTH a tutti gli users
  return (
    <div>
      <h1>Users Table</h1>
      <Table className={styles.usersTable}>
        <thead>
          <tr>
            <th>ID</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th> Date of birth</th>
            <th>Country of residence</th>
            <th>Email</th>
            <th>Password</th>
            <th>Admin</th>

          </tr>
        </thead>
        <tbody>
          {usersData.map((user) => (
            <tr key={user.objectId}>
              <td>{user.objectId}</td>
              <td>{user.firstName}</td>
              <td>{user.lastName}</td>
              <td>{user.dateOfBirth}</td>
              <td>{user.countryOfResidence}</td>
              <td>{user.email}</td>
              <td>{user.password}</td>
              <td>{user.isAdmin}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default UsersTable;
