// Import react and router DOM
import React from "react";
import { Link } from "react-router-dom";

// Import states, contexts and CSS
import { Col, Row, Container } from "react-bootstrap";
import styles from "./aboutPage.module.scss";

const AboutPage = () => {
  return (
    <Container className={`${styles["login-container"]} my-5`}>
      <Row>
        <Col xs={0} md={2} lg={3}></Col>
        <Col xs={12} md={8} lg={6}>
          <h1>About Us</h1>
          <p>
            Welcome to Smart Bag Pro! Founded by an experienced professional
            with a decade-long background in the plastic bag manufacturing
            industry, Smart Bag Pro is dedicated to revolutionizing the way
            companies produce plastic and paper bags.
          </p>
          <h3>Our Story</h3>
          <p>
            Born and raised in Italy, our founder spent ten years honing their
            expertise in a leading plastic bag manufacturing company. With an
            intimate understanding of the industry's challenges and
            opportunities, they embarked on a mission to develop a solution
            that would streamline production processes, optimize costs, and
            enhance overall efficiency.
          </p>
          <h3>Our Mission</h3>
          <p>
            At Smart Bag Pro, our mission is clear: to empower bag manufacturing
            businesses with innovative tools and technologies that propel them
            towards success. We believe that by leveraging cutting-edge software
            solutions, companies can overcome obstacles, maximize productivity,
            and thrive in today's competitive market landscape.
          </p>
          <h3>Our Vision</h3>
          <p>
            Our vision is to become the go-to platform for bag manufacturers
            worldwide, offering comprehensive software solutions tailored to
            their specific needs. We envision a future where every bag
            manufacturing operation, regardless of size or scale, can access
            advanced tools that drive growth, profitability, and sustainability.
          </p>
          <p>
            <Link to="/createaccount">Join us on this journey as we reshape the future
              of bag manufacturing together.</Link>
          </p>
        </Col>
        <Col xs={0} md={2} lg={3}></Col>
      </Row>
    </Container>
  );
};

export default AboutPage;

